import { __spreadArray } from "tslib";
import Header from "../../../components/UI/Header.vue";
import { GeneralFieldsDefinition } from "features/events/form/general-fields/GeneralFields.vue";
import { ReminderFieldsDefinition } from "components/EventForm/ReminderFields.vue";
import { DangerZoneFieldsDefinition } from "components/EventForm/DangerZoneFields.vue";
import { OrganizationFieldsDefinition } from "features/events/form/organization-fields/OrganizationFields.vue";
import { mdiChevronDown } from "@mdi/js";
import { computed, defineComponent, ref, } from "vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import { PanelIdentifier } from "features/events/form/event-form.types";
export default defineComponent({
    name: "FormExpansionPanel",
    components: {
        Header: Header,
    },
    props: {
        hasDangerZone: { type: Boolean, default: false },
        allowedToPublish: { type: Boolean, default: false },
    },
    data: function () {
        return {
            panelIcon: mdiChevronDown,
        };
    },
    methods: {
        addActivePanel: function (panelId) {
            if (!this.activePanels.includes(panelId)) {
                this.activePanels.push(panelId);
            }
        },
        validateForm: function () {
            this.$emit("validate");
        },
    },
    setup: function (props) {
        var t$ = useTranslate("components.event_form").t$;
        var panels = computed(function () {
            var panelDefinitions = __spreadArray([
                GeneralFieldsDefinition,
                OrganizationFieldsDefinition,
                ReminderFieldsDefinition
            ], (props.hasDangerZone ? [DangerZoneFieldsDefinition] : []), true);
            return panelDefinitions.map(function (panelDefinition) { return ({
                title: t$("." + panelDefinition.key),
                selector: "suus__event-form__".concat(panelDefinition.key),
                component: panelDefinition.component,
                id: panelDefinition.id,
            }); });
        });
        var activePanels = ref([
            PanelIdentifier.GENERAL,
            PanelIdentifier.ORGANIZATION,
            PanelIdentifier.REMINDER,
        ]);
        var activePanelsIndizes = computed({
            get: function () {
                return activePanels.value
                    .filter(function (panelId) {
                    return panels.value.some(function (panel) { return panel.id === panelId; });
                })
                    .map(function (panelId) {
                    return panels.value.findIndex(function (panel) { return panel.id === panelId; });
                });
            },
            set: function (value) {
                activePanels.value = value.map(function (index) { return panels.value[index].id; });
            },
        });
        return {
            panels: panels,
            activePanels: activePanels,
            activePanelsIndizes: activePanelsIndizes,
        };
    },
});
